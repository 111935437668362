/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getDocumentDetails } from '../../../../../_utils/api';

const { actions, reducer } = createSlice({
  name: 'dataDocumentDetails',
  initialState: {
    documentTitle: '',
    documentDescription: '',
    documentURL: '',
    docThumbnailURL: '',
    //  documentDetails: [],
  },
  reducers: {
    /* setDocumentDetails: (state, { payload }) => {
      state.documentDetails = payload;
    }, */
    setDocumentTitle: (state, { payload }) => {
      state.documentTitle = payload;
    },
    setDocumentDescription: (state, { payload }) => {
      state.documentDescription = payload;
    },
    setDocumentURL: (state, { payload }) => {
      state.documentURL = payload;
    },
    setdocThumbnailURL: (state, { payload }) => {
      state.docThumbnailURL = payload;
    },
  },
});

export default reducer;

export const {
  setDocumentDetails,
  setDocumentTitle,
  setDocumentDescription,
  setDocumentURL,
  setdocThumbnailURL,
} = actions;

export const fetchDocumentDetails = (docId, docType, callback) => (
  dispatch
) => {
  try {
    docType == "letters" ? docType = "letter" : docType == "circulars" ? docType = "circular" : docType == "directives" ? docType = "directive" : docType = docType;
    getDocumentDetails(docId, docType)
      .then((res) => {
        if (res.data.ok) {
          dispatch(setDocumentTitle(res.data.result.title));
          dispatch(setDocumentDescription(res.data.result.description));
          dispatch(setDocumentURL(res.data.result.file_url));
          dispatch(setdocThumbnailURL(res.data.result.thumbnail_url));
          callback(false);
        }
      })
      .catch((_error) => console.log(_error));
  } catch (_error) {
    console.log('in the catch block');
  }
};
