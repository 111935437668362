/* eslint-disable no-param-reassign */
// NEED TO CHECK
import { createSlice } from '@reduxjs/toolkit';
import { API } from "../../../../../services/api.service";
import { listCount } from '../../../../../constants/const';
const { actions, reducer } = createSlice({
    name: 'dataMiscellaneous',
    initialState: {
        filesList: [],
        filesListCopy: [],
        isLoading: false,
        currentPage: 1,
        totalCount: 0,
        query: ""
    },
    reducers: {
        setList: (state, { payload }) => {
            state.filesList = payload;
            state.filesListCopy = payload;
        },
        showLoader: (state, { payload }) => {
            state.isLoading = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setTotalCount: (state, { payload }) => {
            state.totalCount = payload;
        },
        setListWithOutCopy: (state, { payload }) => {
            state.filesList = payload;
        },
        setListKeyWord: (state, { payload }) => {
            state.query = payload;
        },
    },
});

export default reducer;

export const {
    setList,
    setListWithOutCopy,
    showLoader,
    setTotalCount,
    setCurrentPage,
} = actions;

/* export const searchList = (value) =>  (dispatch) => {
  let state = { ...store.getState() };
  if(value) {
    let regex = new RegExp(value.toLowerCase());
    var array = state.dataMiscellaneous.filesListCopy.filter((item)=>regex.test(item.originalName.toLowerCase()))
    dispatch(setListWithOutCopy( array ));
  } else {
    dispatch(setListWithOutCopy( state.dataMiscellaneous.filesListCopy )); 
  }

}; */

/* export const uploadFile = (obj, authToken, callback) => (dispatch) => {
  try {
    upload(obj, authToken)
      .then((res) => {
        if (res.data.ok) {
          callback(false);
        }
      })
      .catch((_error) => {
        callback(
          true,
          _error.response && _error.response.data
            ? _error.response.data.message
            : ''
        );
      });
  } catch (_error) {}
}; */

export const listFile = (authToken, type, pageNo, keyword = '', callback) => async(
    dispatch
) => {
    /*  let state = { ...store.getState() };
    if(state.dataMiscellaneous.filesList.length && !pageNo && !keyword) {
      return
    } */
    if (keyword === '#erased-search*-value') {
        keyword = '';
    }
    if (!pageNo) {
        pageNo = 1;
    }
    dispatch(showLoader(true));
    try {
        const document_id = ''
        const params = {
            page_size: listCount,
            page_no: pageNo,
            keyword: keyword
        }
        const res = await API.MISCELLANEOUS(document_id, { params }).catch((_error) => {
            callback(
                true,
                _error.response && _error.response.data ?
                _error.response.data.message :
                ''
            );
            dispatch(showLoader(false));
        });
        if (res.data.ok) {
            // dispatch(setList(res.data.result.multipleDocData));
            dispatch(setList(res.data.result.items));
            dispatch(setTotalCount(res.data.result.page.total));
            // dispatch(setTotalCount(res.data.result.totalCount))
            dispatch(setCurrentPage(pageNo));
        } else {
            callback(true);
        }
        dispatch(showLoader(false));
        // API.MISCELLANEOUSS({params})
        //   .then((res) => {
        //     if (res.data.ok) {
        //       // dispatch(setList(res.data.result.multipleDocData));
        //       dispatch(setList(res.data.result.items));
        //       dispatch(setTotalCount(res.data.result.page.total));
        //       // dispatch(setTotalCount(res.data.result.totalCount))
        //       dispatch(setCurrentPage(pageNo));
        //     } else {
        //       callback(true);
        //     }
        //     dispatch(showLoader(false));
        //   })
        //   .catch((_error) => {
        //     callback(
        //       true,
        //       _error.response && _error.response.data
        //         ? _error.response.data.message
        //         : ''
        //     );
        //     dispatch(showLoader(false));
        //   });
    } catch (_error) {
        console.log('in catch block');
    }
};

/* export const downloadFile = (id,authToken, callback) => (dispatch) => {
  try {
    download(id,authToken)
      .then((res) => {
        if (res.data.ok) {
        } else {
          callback(true);
        }
      })
      .catch((_error) => {
        callback(true,(_error.response && _error.response.data ? _error.response.data.message:''));
      });
  } catch (_error) {}
};
 */