/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-return-await */
/* eslint-disable no-throw-literal */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */

import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { listCount } from '../constants/const';
// export const BASE_URL = process.env.REACT_APP_DEV_SERVER_BASE_URL
// export const BASE_URL = process.env.REACT_APP_ENV == "development" ? process.env.REACT_APP_LOCAL == "true" ? 'http://localhost:5555' : process.env.REACT_APP_DEV_SERVER_BASE_URL : process.env.REACT_APP_PROD_SERVER_BASE_URL;
// export const BASE_URL = "https://icpapilots.ml";

export const BASE_URL = 'https://dev.icpaunion.com';
// export const BASE_URL = "http://localhost:5555";
// export const BASE_URL = process.env.REACT_APP_ENV == "development" ? process.env.REACT_APP_LOCAL == "true" ? 'http://localhost:5555' : process.env.REACT_APP_DEV_SERVER_BASE_URL : process.env.REACT_APP_PROD_SERVER_BASE_URL;
console.log(process.env);
export const BASE_API_URL = `${BASE_URL}/api`;

export const authToken = () => {
    return localStorage.getItem('access_token');
};

// const getAuthHeader = () => {
//   // handles api authorization
//   try {
//     const authToken =
//       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzExMTMxNTMsImRhdGEiOnsiZW1haWwiOiJzYXlhbGlzYW5raGU5NkBnbWFpbC5jb20iLCJ1c2VySWQiOiI2MGY2ZTI5OTJmYjEwMTQxNmEwZjYyZGUiLCJyb2xlIjpbInBpbG90IiwiYWRtaW4iXX0sImlhdCI6MTYzMTAyNjc1M30.tG9SeV74Np-UQTO4-dGzmxbX7bZk3YONy9ZvPEVEcHM';
//     if (!authToken) {
//       throw 'Your Session has expired! Please login Again';
//     }
//     var decoded = jwt_decode(authToken);
//     if (Date.now() >= decoded.exp * 1000) {
//       throw 'Your Session has expired! Please login Again';
//     }
//     return {
//       Authorization: authToken,
//     };
//   } catch (e) {
//     localStorage.access_token = '';
//     // window.location = window.location.origin + '/login'
//     throw 'Your Session has expired! Please login Again';
//   }
// };

const getAuthHeader = () => {
    // handles api authorization
    try {
      debugger
        const authToken = localStorage.getItem('access_token');
        // const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzEzMDk1NDcsImRhdGEiOnsiZW1haWwiOiJzaHJlZXNoYzk1QGdtYWlsLmNvbSIsInVzZXJJZCI6IjYwZjVkMDdmMWE2Njg2NjU3YzZmNGQzZiIsInJvbGUiOlsidXNlciIsImFkbWluIl19LCJpYXQiOjE2MzEyMjMxNDd9.OT81J851IywRSAU2KK3em8l0jz1aH5iG6SKOVDBs0VM';
        if (!authToken) {
            throw 'Your Session has expired! Please login Again';
        }
        var decoded = jwt_decode(authToken);
        if (Date.now() >= decoded.exp * 1000) {
            throw 'Your Session has expired! Please login Again';
        }
        return {
            Authorization: authToken,
        };
    } catch (e) {
        localStorage.access_token = '';
        // window.location = window.location.origin + '/login'
        throw 'Your Session has expired! Please login Again';
    }
};
/* export const login = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_API_URL}/authorize/login`, { email, password })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}; */

export const login = (userName, password) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_API_URL}/v1.0/user/login`, { userName, password })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/* export const register = (obj) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_API_URL}/authorize/register`, { ...obj })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}; */

export const register = (obj) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_API_URL}/v1.0/user/register`, {...obj })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateProfile = (obj, authToken) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`${BASE_API_URL}/me/updateProfile`, obj, {
                headers: getAuthHeader(),
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/* export const getMyProfile = (authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/me/getMyProfile`, { headers: getAuthHeader() })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}; */
export const getMyProfile = (authToken) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_API_URL}/v1.0/user/profile`, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/* export const reset = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_API_URL}/authorize/resetPassword`, { email })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}; */

export const reset = (email) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_API_URL}/v1.0/user/resetPassword`, { email })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const forgot = (password, id, token) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_API_URL}/v1.0/user/changePassword`, {...password, id, token })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const upload = (data, authToken) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_API_URL}/file/upload`, data, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const docUpload = (data, authToken, formType) => {
    return new Promise((resolve, reject) => {
        let url = `${BASE_API_URL}/doc/upload`;
        if (formType) {
            if (formType !== 'pmu' && formType !== 'tmu') {
                url = `${BASE_API_URL}/report/upload`;
            }
        }

        axios
            .post(url, data, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const docUploadGMC = (data, authToken) => {
    return new Promise((resolve, reject) => {
        let url = `${BASE_API_URL}/v1.0/file-store`;

        axios
            .put(url, data, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const schemes = (data, type) => {
    return new Promise((resolve, reject) => {
        const _type = {
            tmu: `${BASE_API_URL}/tmu/raiseTmu`,
            pmu: `${BASE_API_URL}/pmu/raisePmu`,
            incident: `${BASE_API_URL}/report/submitIncidentReport`,
            accident: `${BASE_API_URL}/report/submitAccidentReport`,
            adminstrator: `${BASE_API_URL}/report/submitAdminReport`,
            policy: `${BASE_API_URL}/report/submitPolicy`,
            gmcpolicy: `${BASE_API_URL}/v1.0/policy/gmc/`,
        };
        axios
            .post(_type[data.formType], data, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getMyPolicyGMC = (policy, authToken, pageNo) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_API_URL}/v1.0/policy/gmc`, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getMyPolicyMail = (policy, authToken, pageNo) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_API_URL}/v1.0/policy/gmc/mail_my_policy`, {
                headers: getAuthHeader(),
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const raisePmu = (data, authToken) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_API_URL}/pmu/raisePmu`, data, { headers: getAuthHeader() })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const list = (authToken, type, pageNo = 1, keyword = '') => {
        return new Promise((resolve, reject) => {
                    axios
                        .get(
                            `${BASE_API_URL}/v1.0/document/${type}?page_size=${listCount}&page_no=${pageNo}${
          keyword ? `&keyword=${keyword}` : ''
        }`,
        // &keyword=${keyword}`,
        { headers: getAuthHeader() }
      )
      .then((response) => {
        console.log('response', response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* export const getNews = (authToken) => {
  const keyword = 'Aviation';
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/news?search=${keyword}`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}; */

export const getNews = (authToken) => {
  const keyword = 'Aviation';
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/v1.0/news?search=${keyword}`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const download = (id, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/file/render?docId=${id}`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const notificationList = (authToken, pageNo) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_API_URL}/me/getMyNotficationList?pageSize=1000&pageNum=${pageNo}`,
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const markAsRead = (id, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/me/markAsRead?docId=${id}`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const raiseGrevience = (data, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BASE_API_URL}/me/raiseGrievance`,
        { ...data },
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const raiseFlightRequest = (data, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BASE_API_URL}/me/createFlightRequest`,
        { ...data },
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getGrevience = (authToken, pageNo) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_API_URL}/me/getGrievanceList?page_size=${listCount}&page_num=${pageNo}`,
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getFlight = (authToken, pageNo) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_API_URL}/me/getflightRequestList?page_size=${listCount}&page_num=${pageNo}`,
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getFlightByDate = (authToken, requestedDate, pageNo) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE_API_URL}/me/getFlightRequestByDate?page_size=${listCount}&page_num=${pageNo}&requestedDate=${requestedDate}`,
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getSchemeDetails = (type, pageNo) => {
  const _type = {
    tmu: `${BASE_API_URL}/tmu/getTmu`,
    pmu: `${BASE_API_URL}/pmu/getPmu`,
    incident: `${BASE_API_URL}/admin/getIncidentReport`,
    accident: `${BASE_API_URL}/admin/getAccidentReport`,
    adminstrator: `${BASE_API_URL}/admin/getAdminReport`,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${_type[type]}?page_size=${listCount}&status=pending&pageNum=${pageNo}`,
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUsers = (authToken, keyword = '', pageNo = 1) => {
  return new Promise((resolve, reject) => {
    axios
      // get(`${BASE_API_URL}/admin/getuserlist?pageSize=100&pageNum=1`, {
      .get(
        `${BASE_API_URL}/admin/getuserlist?pageSize=${100}&pageNum=${pageNo}&keyword=${keyword}`,
        {
          headers: getAuthHeader(),
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getMembers = (authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/me/members`, { headers: getAuthHeader() })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setAcknowledged = (id, reply, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BASE_API_URL}/me/ackGrievance?gId=${id}`,
        { ...reply },
        { headers: getAuthHeader() }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteFile = async (id) => {
  return await axios.delete(`${BASE_API_URL}/file`, {
    headers: getAuthHeader(),
    data: { id },
  });
};

export const getImageLink = async (htmllink) => {
  try {
    let res = await axios.get(
      `${BASE_API_URL}/util/linkMetaImage?link=${htmllink}`,
      { headers: getAuthHeader() }
    );
    return res.data && res.data.result ? res.data.result : null;
  } catch (e) {
    return null;
  }
};

export const checkMembershipPaymentStatus = async () => {
  try {
    let res = await axios.get(
      `${BASE_API_URL}/payment/checkMembershipPaymentStatus`,
      { headers: getAuthHeader() }
    );
    return res;
  } catch (e) {
    return null;
  }
};
export const payRegistrationFee = async () => {
  try {
    let res = await axios.post(
      `${BASE_API_URL}/payment/payRegistrationFee`,
      {},
      { headers: getAuthHeader() }
    );
    return res;
  } catch (e) {
    return null;
  }
};

export const payVariableFee = async (data) => {
  try {
    let res = await axios.post(
      `${BASE_API_URL}/payment/pay/icpa`,
      { ...data },
      { headers: getAuthHeader() }
    );
    return res;
  } catch (e) {
    return null;
  }
};

export const updateUserProfile = async (data) => {
  try {
    let res = await axios.post(
      `${BASE_API_URL}/admin/updateUserProfile`,
      { ...data },
      { headers: getAuthHeader() }
    );
    return res;
  } catch (e) {
    return null;
  }
};

export const sendRegistrationToken = async (
  email,
  registration_token,
  isWeb,
  authToken
) => {
  console.log('sendToken', email, registration_token);

  try {
    let res = await axios.post(
      `${BASE_URL}/api/notify/sendRegistrationToken`,
      { email, registration_token, isWeb: true },
      { headers: { Authorization: authToken } }
    );
    return res;
  } catch (e) {
    console.log('e', e);
    return e;
  }
};
/* export const addBulletin = async (obj, authToken) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/bulletin/addBulletin`, obj, {
      headers: { Authorization: authToken },
    });
    return res;
  } catch (e) {
    console.log('e', e);
    return e;
  }
}; */

export const addBulletin = async (obj, authToken) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/v1.0/bulletin`, obj, {
      headers: { Authorization: authToken },
    });
    return res;
  } catch (e) {
    console.log('e', e);
    return e;
  }
};

export const getBulletins = (authToken, pageNo) => {
  return new Promise((resolve, reject) => {
    axios
      // .get(`${BASE_API_URL}/bulletin/getBulletinList`, {
      // .get(`${BASE_API_URL}/bulletin/getBulletinList?pageSize=${listCount}&pageNum=${pageNo}
      // `${BASE_API_URL}/bulletin/getBulletinList?pageNum=${pageNo}&pageSize=${listCount}`,
      .get(`${BASE_API_URL}/v1.0/bulletin`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const premiumStatus = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/report/premiumStatus`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const premiumStatusGMC = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_API_URL}/v1.0/policy/gmc`, {
        headers: getAuthHeader(),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadFileByURL = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: getAuthHeader(), responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        resolve(true);
      });
  });
};

export const getDocumentDetails = (docId, docType) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        // `${BASE_API_URL}/v1.0/document?document_type=${docType}&document_id=${docId}`,
        `${BASE_API_URL}/v1.0/document/${docType}/${docId}`,
        {
          headers: getAuthHeader(),
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};