import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import dataLogin from '../views/user/slices/index';
import dataUser from '../views/app/user/slices/index';
import dataDocumentDetails from '../views/app/documents/documentdetails/slices/index';
import dataLetters from '../views/app/documents/letters/slices/index';
import dataFlightSafety from '../views/app/documents/flightsafety/slices/index';
import dataCirculars from '../views/app/documents/circulars/slices/index';
import dataDirectives from '../views/app/documents/directives/slices/index';
import dataMiscellaneous from '../views/app/documents/miscellaneous/slices/index';
import dataBulletin from '../views/app/bulletin/slices/index';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  dataLogin,
  dataUser,
  dataLetters,
  dataFlightSafety,
  dataCirculars,
  dataDirectives,
  dataMiscellaneous,
  dataDocumentDetails,
  dataBulletin,
});

export default reducers;
