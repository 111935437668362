require('dotenv').config() // load .env file
const environment = process.env;

module.exports = APP_CONFIG = {
    env: environment.NODE_ENV,
    server: {
        uri: environment.NODE_ENV == 'production' ? environment.REACT_APP_PROD_BACKEND_BASE_URL : environment.REACT_APP_DEV_BACKEND_BASE_URL
    },
    web: {
        uri: environment.NODE_ENV == 'production' ? environment.REACT_APP_PROD_FRONTEND_BASE_URL : environment.REACT_APP_DEV_FRONTEND_BASE_URL
    }
}