/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { API } from "../../../../services/api.service";
import { listCount } from '../../../../constants/const';
const { actions, reducer } = createSlice({
    name: 'dataUser',
    initialState: {
        user: {},
        isLoading: false,
    },
    reducers: {
        setUserData: (state, { payload }) => {
            state.user = payload;
        },
        showLoader: (state, { payload }) => {
            state.isLoading = payload;
        },
    },
});

export default reducer;

export const {
    showLoader,
    setUserData
} = actions;

export const getUserData = () => async(
    dispatch
) => {
    dispatch(showLoader(true));
    try {
        API.PROFILE({})
            .then((res) => {
                if (res.data.ok) {
                    dispatch(setUserData(res.data.result));
                } else {
                    callback(true);
                }
                dispatch(showLoader(false));
            })
            .catch((_error) => {
                callback(
                    true,
                    _error.response && _error.response.data ?
                    _error.response.data.message :
                    ''
                );
                dispatch(showLoader(false));
            });
    } catch (_error) {
        console.log('this is catch block');
    }
};
export const putUserData = () => async(
    dispatch
) => {
    dispatch(showLoader(true));
    try {
        API.PROFILEUPDATE({})
            .then((res) => {
                if (res.data.ok) {
                    //   dispatch(setUserData(res.data.result.items));
                } else {
                    callback(true);
                }
                dispatch(showLoader(false));
            })
            .catch((_error) => {
                callback(
                    true,
                    _error.response && _error.response.data ?
                    _error.response.data.message :
                    ''
                );
                dispatch(showLoader(false));
            });
    } catch (_error) {
        console.log('this is catch block');
    }
};