/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import {
    login,
    register,
    reset,
    forgot,
    getMyProfile,
} from '../../../_utils/api';
import { API } from "../../../services/api.service";
import { useJwt } from '../../../services/jwt.service';


const { actions, reducer } = createSlice({
    name: 'dataLogin',
    initialState: {
        token: '',
        isLoading: false,
        isLoggedIn: false,
        details: {
            role: '',
            airline: 'AIRINDIA',
            id: -1,
            firstName: '',
            lastName: '',
            email: '',
            gender: 'male',
            dob: '',
            region: 'east',
            contact: '',
            fireBaseDetails: [],
        },
    },
    reducers: {
        setToken: (state, { payload }) => {
            state.token = payload.token;
        },
        setDetails: (state, { payload }) => {
            state.details = payload.details;
        },
        setLoader: (state, { payload }) => {
            state.isLoading = payload.status;
        },
        setLoggedIn: (state, { payload }) => {
            state.isLoggedIn = payload;
        },
    },
});
export default reducer;
export const { setToken, setLoader, setDetails, setLoggedIn } = actions;

export const checkLogin = (userName, password, callback) => (dispatch) => {
    try {
        API.LOGIN({ userName, password })
            .then((res) => {
                if (res.data.ok) {

                    const result_data = res.data.result;

                    // Set data to local storage
                    useJwt.setAccessToken(result_data.auth_token);
                    useJwt.setUserInfo(result_data.userData);

                    dispatch(setToken({ token: res.data.result.auth_token }));
                    dispatch(setLoggedIn(true));
                    callback(false);
                }
            })
            .catch((_error) => {
                console.log("ERROR IN LOGIN:::", _error)
                callback(
                    true,
                    _error.response && _error.response.data ?
                    _error.response.data.message :
                    ''
                );
            });
    } catch (_error) {}
};

export const signupUser = (obj, callback) => (dispatch) => {
    try {
        API.REGISTER(obj)
            .then((res) => {
                if (res.data.ok) {
                    dispatch(setLoader(false));
                    callback(false);
                }
            })
            .catch((_error) => {
                callback(
                    true,
                    _error.response && _error.response.data ?
                    _error.response.data.message :
                    ''
                );
            });
    } catch (_error) {}
};

export const resetPassword = (email, callback) => (dispatch) => {
    try {
        API.RESET({email})
            .then((res) => {
                if (res.data.ok) {
                    dispatch(setLoader(false));
                    callback(false, res.data.result);
                }
            })
            .catch((_error) => {
                console.log("ERROR IN LOGIN:::", _error)
                callback(
                    true,
                    _error.response && _error.response.data ?
                    _error.response.data.message :
                    ''
                );
            });
    } catch (_error) {}
};
export const forgotPassword = (password, id, token, callback) => (dispatch) => {
    try {

        API.FORGOT({password, id, token})
            .then((res) => {
                if (res.data.ok) {
                    dispatch(setLoader(false));
                    callback(false, res.data.result);
                }
            })
            .catch((_error) => {
                callback(
                    true,
                    _error.response && _error.response.data ?
                    _error.response.data.message :
                    ''
                );
            });
    } catch (_error) {}
};

export const logout = () => (dispatch) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('icpa_details');
    dispatch(setToken({ token: null }));
    dispatch(setToken({ token: null }));
    dispatch(setLoggedIn(false));
    document.body.classList.remove('hidden');
};

export const getUserProfile = (authToken, callback = () => {}) => (
    dispatch
) => {
    try {
        dispatch(setLoader(true));
        dispatch(setLoggedIn(false));
        getMyProfile(authToken)
            .then((res) => {
                if (res.data.ok) {
                    let response = res.data.result;
                    let _details = {
                        role: response.role,
                        airline: response.airline,
                        id: response._id,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        email: response.email,
                        gender: response.gender,
                        dob: response.dob,
                        region: response.region,
                        contact: response.contact,
                        city: response.city,
                        state: response.state,
                        address: response.address,
                        pincode: response.pincode,
                        fireBaseDetails: response.fireBaseDetails,
                    };
                    dispatch(setDetails({ details: _details }));
                    localStorage.setItem('icpa_details', JSON.stringify(_details));
                    dispatch(setToken({ token: authToken }));
                    dispatch(setLoader(false));
                    dispatch(setLoggedIn(true));
                }
            })
            .catch((_error) => {
                console.log(_error);
                callback(true);
                dispatch(setLoader(false));
            });
    } catch (_error) {}
};