import { APP_CONSTANT } from "../constants/app.constant";
import axios from 'axios';
import { clearUserSession } from "../helpers/Utils";
class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig }

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage
                const accessToken = this.getAccessToken()

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${accessToken}`
                }
                return config
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config
                if (response && response.status) {
                    debugger
                    if (response.status === 401) {
                        // UnAuthroized or Session Expired
                        clearUserSession();
                        const new_path = `/auth/login?redirect=${window.location.pathname}`
                        window.location.href = new_path;

                    }
                    if (response.status === 403) {
                        // Forbidden or Membership Expired
                        const new_path = `/app/membership`
                        window.location.href = new_path;
                    }
                }

                return Promise.reject(error)
            },
        )
    }


    getAccessToken() {
        return localStorage.getItem(APP_CONSTANT.USER_ACCESS_TOKEN)
    }

    setAccessToken(value) {
        localStorage.setItem(APP_CONSTANT.USER_ACCESS_TOKEN, value)
            // Exclusively for app
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage && window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'web-session-info',
            payload: {
                [APP_CONSTANT.USER_ACCESS_TOKEN]: value
            }
        }));
    }

    setUserInfo(value) {
        localStorage.setItem(APP_CONSTANT.USER_INFO, JSON.stringify(value))
            // Exclusively for app
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage && window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'web-session-info',
            payload: {
                [APP_CONSTANT.USER_INFO]: value
            }
        }));

    }
    getUserInfo(value) {
        return JSON.parse(localStorage.getItem(APP_CONSTANT.USER_INFO))
    }

    getAuthenticatedInterceptor() {
        return this.axiosIns;
    }


}

export const useJwt = new JwtService(axios, {});