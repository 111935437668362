/* eslint no-useless-escape: "off" */
/* eslint-disable no-underscore-dangle */

export const navMenus = [
    { name: 'Home', url: '/home', icon: 'fa fa-home', ExtPilot: true },
    {
        name: 'News ',
        url: '/latest-news',
        icon: 'fa fa-newspaper',
        ExtPilot: true,
    },
    {
        name: 'Directives',
        url: '/directive',
        icon: 'fa fa-file',
        ExtPilot: false,
    },
    {
        name: 'Flight Safety',
        url: '/flight-safety',
        icon: 'fa fa-shield-alt',
        ExtPilot: true,
    },
    { name: 'Letters', url: '/letter', icon: 'fa fa-envelope', ExtPilot: false },
    {
        name: 'Circulars',
        url: '/circular',
        icon: 'fa fa-file-alt',
        ExtPilot: false,
    },
    {
        name: ' Miscellaneous',
        url: '/miscellaneous',
        icon: 'fa fa-file',
        ExtPilot: true,
    },
    // { name: "Forms ", url: "/online-forms",icon:"fa fa-list" },
    // { name: "Flight Request", url: "/flight-request", icon: "fa fa-plane" },
    // { name: "Membership", url: "/membership",icon:"fa newspaper-o" },
    {
        name: 'Payment',
        url: '/payment',
        icon: 'fa fa-credit-card',
        ExtPilot: true,
    },
    {
        name: 'Notifications',
        url: '/notifications',
        icon: 'fa fa-bell',
        ExtPilot: true,
    },

    // { name: "Contact Us", url: "/contact-us",icon:"fa newspaper-o" },
    {
        name: 'Grievance',
        url: '/grevience-section',
        icon: 'fa fa-phone',
        ExtPilot: true,
    },
    {
        name: 'Constitution',
        url: '/icpa-constitution',
        icon: 'fa fa-book',
        ExtPilot: true,
    },
    // { name: "ICPA Schemes", url: "/icpa-schemes",icon:"fa fa-tasks" },
    {
        name: 'Welfare Scheme',
        url: '/pmu-tmu-forms',
        icon: 'fa fa-file',
        ExtPilot: true,
    },
    { name: 'Members', url: '/memberslist', icon: 'fa fa-user', ExtPilot: true },

    // { name: "About Us", url: "/about-us",icon:"fa fa-users" },
    {
        name: 'Bulletin',
        url: '/bulletin',
        icon: 'fa fa-sticky-note',
        ExtPilot: false,
    },
    {
        name: 'Admin',
        url: '/admin',
        icon: 'fa fa-user',
        isAdmin: true,
        ExtPilot: false,
    },
    // { name: "Users", url: "/users", icon:"fa fa-user", isAdmin:true },
];
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const phoneRegex = /^[6-9]\d{9}$/;
export const flightNoRegex = /^[0-9]{3,6}$/;
export const listCount = 8;
export const AImembershipPlans = [{
    id: "1001",
    amount: "500.00",
    name: "Monthly Subscription - Air India"
},
{
    id: "1002",
    amount: "6000.00",
    name: "Annual Subscription - Air India"
},
]
export const ExtmembershipPlans = [{
    id: "2001",
    amount: "1500.00",
    name: "Monthly Subscription"
},]
export const urlMap = {
    letters: 'letters',
    letter: 'letters',
    circulars: 'latest-circulars',
    circular: 'latest-circulars',
    'online-forms': 'online-forms',
    onlineforms: 'online-forms',
    online_forms: 'online-forms',
    onlineForms: 'online-forms',
    'flight-safety': 'flight-safety',
    flighsafety: 'flight-safety',
    memberslist: 'memberslist',
    memberlist: 'memberslist',
    'members-list': 'memberslist',
    'member-list': 'memberslist',
    achievements: 'home',
    achievement: 'home',
    'pmu-tmu-forms': 'pmu-tmu-forms',
    'pmutmu-forms': 'pmu-tmu-forms',
    pmutmuforms: 'pmu-tmu-forms',
    'pmu-tmu-form': 'pmu-tmu-forms',
    'icpa-constitution': 'icpa-constitution',
    'icpa-schemes': 'icpa-schemes',
    icpaschemes: 'icpa-schemes',
    icpaconstitution: 'icpa-constitution',
    'miscellaneous-docs': 'miscellaneous-docs',
    miscellaneous: 'miscellaneous-docs',
};
export const options = {
    position: 'top-right',
    bar: { size: '0' },
    hideAfter: 5,
};

export const indianAirlines = [
    { name: 'Air India', iata: 'AI', icao: 'AIC', call_sign: 'airindia' },
    { name: 'Air India Express', iata: 'IX', icao: 'AXB', call_sign: 'airindia_express' },
    { name: 'Alliance Air', iata: 'QQ', icao: 'UTY', call_sign: 'allianceair' },
    {
        name: 'AirAsia India',
        iata: 'I5',
        icao: 'IAD',
        call_sign: 'airasia_india',
    },
    { name: 'GoAir', iata: 'G8', icao: 'GOW', call_sign: 'goair' },
    { name: 'IndiGo', iata: '6E', icao: 'IGO', call_sign: 'indigo' },
    { name: 'SpiceJet', iata: 'SG', icao: 'SEJ', call_sign: 'spicejet' },
    { name: 'TruJet', iata: '2T', icao: 'TRJ', call_sign: 'trujet' },
    { name: 'Vistara', iata: 'UK', icao: 'VTI', call_sign: 'vistara' },
    { name: 'Jet Airways', iata: '9W', icao: 'JAI', call_sign: 'jet_airways' },
    // {
    //     name: 'Other',
    //     iata: 'IX',
    //     icao: 'AXB',
    //     call_sign: 'other',
    // },
];

export const _role = [
    { name: 'Admin', key: 'admin' },
    { name: 'Manager', key: 'manager' },
    { name: 'Pilot', key: 'pilot' },
    // { name: "Ext. Pilot", key: "externalPilot" },
];
export const _gender = [
    { name: 'Male', key: 'male' },
    { name: 'Female', key: 'female' },
    // { name: 'Other', key: 'other' },
];

export const checkboxes = [{
    name: 'east',
    key: 'east',
    label: 'East',
},
{
    name: 'west',
    key: 'west',
    label: 'West',
},
{
    name: 'north',
    key: 'north',
    label: 'North',
},
{
    name: 'south',
    key: 'south',
    label: 'South',
},
];
export const posts = [{
    name: 'Pilot',
    key: 'p1',
},
{
    name: 'Co-Pilot',
    key: 'p2',
},
];
export const pilot_designations = [{
    name: 'Executive Commander',
    key: 'executive_commander',
},
{
    name: 'Commander',
    key: 'commander',
},
{
    name: 'First Officer',
    key: 'first_officer',
},
];
export const designations = [{
    name: 'Commander',
    key: 'commander',
},
{
    name: 'Executive Commander',
    key: 'executiveCommander',
},
{
    name: 'First Officer',
    key: 'firstOfficer',
},
];

export const saltKey =
    'zs:qs5J1HDq?i(h-cHJ+[Y_R2bUitz6WZqv#kEQG.-IN++w^3.|w~>ztutEYZm|j';

export const committeeMembers = [

    {
        name: 'Ina Roy Chowdhury',
        contact: '9831162222',
        email: 'inarc@rediffmail.com',
        region: 'east',
        gender: 'female',
        central: { designation: 'Central President' },
        regional: { designation: 'Regional Secretary' },
    },
    {
        name: 'Pawanjot Singh',
        contact: '8879080491',
        email: 'pjs_rock2@yahoo.co.in',
        region: 'north',
        gender: 'male',
        central: { designation: 'Vice President' },
        regional: { designation: 'Regional Treasurer' },
    },
    {
        name: 'T.Praveen Keerthi',
        contact: '9884077088',
        email: 'praveenpilot@gmail.com',
        region: 'south',
        gender: 'male',
        central: { designation: 'General Secretary' },
        regional: { designation: 'Regional Secretary' },
    },
    {
        name: 'Arun Balachandran',
        contact: '9837300320',
        email: 'arunbalachandran21@gmail.com',
        region: 'west',
        gender: 'male',
        central: { designation: 'Asst. General Secretary' },
        regional: { designation: 'Regional Secretary' },
    },
    {
        name: 'Kiran Savula',
        contact: '9966608686',
        email: 'kiransavula@gmail.com',
        region: 'south',
        gender: 'male',
        central: { designation: 'Central Treasurer' },
        regional: { designation: 'Regional Treasurer' },
    },
    {
        name: 'Gaurav Kukreja',
        contact: '9810872320',
        email: 'gauravkukreja051@gmail.com',
        region: 'west',
        gender: 'male',
        central: { designation: 'Committee Member' },
        regional: { designation: 'Regional Secretary' },
    },
    {
        name: 'Kushal Patil',
        contact: '9869651119',
        email: 'kushal173@yahoo.com',
        region: 'west',
        gender: 'male',
        central: { designation: 'Committee Member' },
        regional: { designation: 'Regional President' },
    },
    {
        name: 'Kshitij Verma',
        contact: '9810771777',
        email: 'jetverma@gmail.com',
        region: 'north',
        gender: 'male',
        central: { designation: 'Committee Member' },
        regional: { designation: 'Asst. Regional Secretary' },
    },
    {
        name: 'F. P. Mogrelia',
        contact: '9831138238',
        email: 'fred737@hotmail.com',
        region: 'east',
        gender: 'male',
        central: { designation: 'Committee Member' },
        regional: { designation: 'Regional President' },
    },

];
export const file_name_map = {
    'diagnosis': "Diagnosis Report",
    'leaveCard': "Leave Card reflecting SL",
    'hospSummary': " Surgery / hospitalization summary",
    'fitCertHosp': "Fitness certificate by Hospital/Doctor/Consultant",
    'medCertHosp': "Medical certificate by Hospital/Doctor/Consultant",
    'fitCertAI': "Fitness certificate by Air India -I",
    'medCertAI': "Medical certificate by Air India –I",
    'unfitnessCert': "CA 35 / Unfitness Certificate",
}